/*
 * @Author: HWZ
 * @Date: 2022-07-01
 * @LastEditTime: 2022-10-17 17:59:09
 * @LastEditors: HWZ
 * @Description: 路由配置页
 */

import { lazy } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import './App.scss'

const Header = lazy(() => import('./modules/component/header/index')); // 头部组件

const Login = lazy(() => import('./modules/containers/login/index')) // 登录
const Register = lazy(() => import('./modules/containers/register/index')) // 注册
const Reset = lazy(() => import('./modules/containers/reset/index')) // 重置密码

const Home = lazy(() => import('./modules/containers/home/index')) // HOME

// legal
const CancellationPolicy = lazy(() => import('./modules/containers/legal/cancellationPolicy/index'))
const Cookie = lazy(() => import('./modules/containers/legal/cookie/index'))
const Privacy = lazy(() => import('./modules/containers/legal/privacy/index'))
const RefundPolicy = lazy(() => import('./modules/containers/legal/refundPolicy/index'))
const TermsOfServices = lazy(() => import('./modules/containers/legal/termsOfServices/index'))

const Pricing = lazy(() => import('./modules/containers/pricing/index')) // pricing
const Order = lazy(() => import('./modules/containers/order/index')) // Order
const Generate = lazy(() => import('./modules/containers/generate/index')) // Generate
const About = lazy(() => import('./modules/containers/about/index')) // About
const Contact = lazy(() => import('./modules/containers/contact/index')) // Contact

const Overview = lazy(() => import('./modules/containers/overview/index')) // Overview
const History = lazy(() => import('./modules/containers/history/index')) // History

const Footed = lazy(() => import('./modules/component/footer/index')) // 头部组件

const App = () => {
  const content = () => {
    return (
      <div className="Appclanme">
        <Header />

        <div className="body-box">
          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="/mushroom" exact element={<Home />} />

            <Route path="/login" exact element={<Login />} />
            <Route path="/register" exact element={<Register />} />
            <Route path="/reset" exact element={<Reset />} />

            <Route path="/cancellationPolicy" exact element={<CancellationPolicy />} />
            <Route path="/cookie" exact element={<Cookie />} />
            <Route path="/privacy" exact element={<Privacy />} />
            <Route path="/refundPolicy" exact element={<RefundPolicy />} />
            <Route path="/termsOfServices" exact element={<TermsOfServices />} />

            <Route path="/overview" exact element={<Overview />} />
            <Route path="/history" exact element={<History />} />
            
            <Route path="/order" exact element={<Order />} />
            <Route path="/pricing" exact element={<Pricing />} />
            <Route path="/generate" exact element={<Generate />} />
            <Route path="/about" exact element={<About />} />
            <Route path="/contact" exact element={<Contact />} />
          </Routes>
        </div>

        <Footed />
      </div>
    )
  }

  return <BrowserRouter basename={`/`}>{content()}</BrowserRouter>
}

export default App
